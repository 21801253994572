<template>
  <highcharts class="wrapper-maturity-chart" :options="options" />
</template>

<script>
import {
  min,
  max,
  mean as getMeanVal,
  standardDeviation,
} from "simple-statistics";
import range from "lodash/range";
import { computed } from "@vue/runtime-core";
export default {
  props: {
    industryMaturityScore: {
      type: Array,
      default: [],
    },
    maturityScore: {
      type: [Number, String],
      default: 0,
    },
    industryName: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const data = computed(() => props.industryMaturityScore);

    const averageScores = data.value.map(({ avgMaturity }) => avgMaturity);
    const lowerBound = min(averageScores),
      upperBound = max(averageScores);

    const normalY = (x, mean, stdDev) =>
      Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));

    const getMean = (averageScores) => getMeanVal(averageScores);

    // distance between mean and each bound of a 95% confidence interval
    // is 2 stdDeviation, so distance between the bounds is 4
    const getStdDeviation = (averageScores) => standardDeviation(averageScores);

    let stdDev = getStdDeviation(averageScores);

    const generatePoints = (lowerBound, upperBound) => {
      let min = 0;
      let max = 90;
      let unit = (max - min) / 100;
      return range(min, max, unit);
    };

    let mean = getMean(averageScores);
    let points = generatePoints(lowerBound, upperBound);

    const getRenderPosXUserInfoScorePlot = (score) => {
      if (score <= 14) {
        return 180;
      }
      return -22;
    };

    let marturityScore = props.maturityScore;
    //TODO: Hidden prepare for anything change
    // let userPlotLineOption = {
    //   label: {
    //     useHTML: true,
    //     text: `<div class="wrapper-info-maturity">
    //             <p>Your Score: ${props.maturityScore}</p>
    //           </div>`,
    //     verticalAlign: "top",
    //     textAlign: "right",
    //     style: {
    //       fontWeight: "bold",
    //     },
    //     x: getRenderPosXUserInfoScorePlot(marturityScore),
    //     y: 30,
    //     rotation: 0,
    //   },
    //   zIndex: 4,
    //   color: "transparent",
    //   width: 2,
    //   zindex: 2,
    //   value: marturityScore,
    // };

    let seriesData = points.map((x) => ({ x, y: normalY(x, mean, stdDev) }));

    let getYPositionIconCurrentScore = computed(() => {
      const closestObject = seriesData.reduce(function(prev, curr) {
        return (Math.abs(curr.x - marturityScore) < Math.abs(prev.x - marturityScore) ? curr : prev);
      });
      const floorChartY = 260;
      return floorChartY - closestObject.y * 220
    });

    let youAreHereLabel = {
      label: {
        text: `
          You are here
        `,
        style: {
          fontWeight: "bold",
        },
        x: -40,
        y: getYPositionIconCurrentScore.value - 20,
        rotation: 0,
        width: 50,
      },
      color: "transparent",
      zindex: 10,
      value: marturityScore, // Position, you'll have to translate this to the values on your x axis
    };

    let IconOrganizationScore = {
      label: {
        useHTML: true,
        text: `
          <div class="arrow-down"></div>
        `,
        x: -20,
        y: getYPositionIconCurrentScore.value,
        rotation: 0,
        width: 50,
      },
      y: 0,
      color: "transparent",
      zindex: 6,
      value: marturityScore, // Position, you'll have to translate this to the values on your x axis
    };

    const options = {
      credits: {
        enabled: false,
      },

      chart: {
        type: "area",
        style: {
          fontFamily: "Gotham, sans-serif",
        },
      },
      yAxis: {
        visible: false,
      },
      title: {
        text: `Your Industry: ${props.industryName} `,
        style: {
          fontWeight: "bolder",
          color: '#3526bf'
        },
      },
      xAxis: {
        max: 92,
        minorTickInterval: 15,
        tickInterval: 15,
        plotBands: [
          {
            from: 85,
            to: 90,
            zIndex: 4,
            label: {
              text: "Total Score",
              verticalAlign: "bottom",
              y: 40,
            },
            color: "transparent",
          },
          {
            from: 0,
            to: 30,
            zIndex: 4,
            label: {
              text: "Newcomer",
              verticalAlign: "bottom",
              y: 0,
            },
            color: "transparent",
          },
          {
            from: 30,
            to: 45,
            zIndex: 4,
            label: {
              text: "Explorer",
              verticalAlign: "bottom",
              y: 0,
            },
            color: "transparent",
          },
          {
            from: 45,
            to: 60,
            zIndex: 4,
            label: {
              text: "Challenger",
              verticalAlign: "bottom",
              y: 0,
            },
            color: "transparent",
          },
          {
            from: 60,
            to: 75,
            zIndex: 4,
            label: {
              text: "Practitioner",
              verticalAlign: "bottom",
              y: 0,
            },
            color: "transparent",
          },
          {
            from: 75,
            to: 90,
            zIndex: 4,
            label: {
              text: "Champion",
              verticalAlign: "bottom",
              y: 0,
            },
            color: "transparent",
          },
        ],
        plotLines: [
          youAreHereLabel,
          IconOrganizationScore,
          {
            label: {
              text: `Industry Score: ${mean.toFixed(1)}`,
              verticalAlign: "top",
              textAlign: "right",
              x: 140,
              y: 180,
              rotation: 0,
              style: {
                fontWeight: "bolder",
              },
            },
            zIndex: 4,
            color: "#595959", // Red
            width: 2,
            zindex: 2,
            value: mean, // Position, you'll have to translate this to the values on your x axis
          },
        ],
        labels: {
          style: {
            fontSize: "15px",
          },
        },
      },
      tooltip: {
        formatter: function() {
          if (this.x) {
            return this.x.toFixed(2);
          }
          return "";
        },
      },
      legend: {
        itemMarginTop: 8,
      },
      series: [
        {
          name: "Normal Distribution Maturity Score",
          data: seriesData,
          showInLegend: false,
        },
        {
          type: "scatter",
          // Series that mimics the plot line
          color: "#CC0000",
          name: "Your Organization Score Based on Your Assessment",
          dashStyle: "solid",
          marker: {
            enabled: true,
            symbol: "triangle-down",
          },
        },
        {
          type: "line",
          // Series that mimics the plot line
          color: "#595959",
          name: "Industry Average Score",
          dashStyle: "solid",
          marker: {
            enabled: false,
          },
        },
      ],

      plotOptions: {
        area: {
          zoneAxis: "x",
          zones: [
            {
              //fillColor gets the inside of the graph, color would change the lines
              fillColor: "#fcc1a6",
              // everything below this value has this style applied to it
              value: 30,
            },
            {
              value: 45,
              fillColor: "#fddeb5",
            },
            {
              value: 60,
              fillColor: "#e5cefd",
            },
            {
              value: 75,
              fillColor: "#b5e4fd",
            },
            {
              value: 90,
              fillColor: "#e0f7b6",
            },
          ],
        },
      },
    };
    return {
      options,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper-maturity-chart {
  &::v-deep {
    .icon-arrow {
      @media (min-width: 960px) {
        font-size: 50px !important;
      }
      font-size: 50px !important;
    }
    .wrapper-info-maturity {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .sub-info {
        margin-top: -8px;
      }
    }

    .arrow-down {
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;

      border-top: 20px solid #cc0000;
    }
  }
}
</style>
